<template>
    <div class='login'>
        <div class='login_center'>
                <div class='title'>{{title}}</div>
                <div class="logo"><img src='@/assets/logo.png' /></div>
                <slot name='content'></slot>
                <div class='wrapbtn'><button @click="setupBtn" class='btn loginbtn' >{{$t(setuptext)}}</button></div>
                <slot name='tolinkclick'></slot>
                <slot name='tolinkclick2'></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NodeprojectLoginsignup',
    components: {  },
    directives: {  },
    props:{
        title:{type:String,default:'DataHunt Maker Portal'},
        setuptext:{type:String,default:'login.btn_1'}
    },
    data() {
        return {
            
        };
    },
    mounted() {
        
    },
    methods: {
        setupBtn(){
            this.$emit('setupBtn', {});
        },
       
    },
};
</script>

<style scoped>
.login{
    /* background-image: linear-gradient(to right,#fbc2eb,#a6c1ee); */
    background-color: rgb(200,29,21);
    height: 100vh;width: 100vw;display:flex;justify-content: center;
    align-items:center;
}
.login .login_center{padding:50px 20px; width: 400px;border-radius: 15px;background-color: #fff;}
.login .title{font-weight:600;text-align:center;line-height: 20px;font-size:16px;}
.login .input-item{ display: block;width: 100%;margin-bottom: 20px;border: none;padding: 10px;border-bottom: 2px solid rgb(128,125,125);font-size: 15px;outline: none;}
.login .wrapbtn{display:flex;}
.login .wrapbtn .loginbtn{width:100%; justify-content: center;padding: 5px;margin-top: 20px; background-image: linear-gradient(to right,#fbc2eb,#a6c1ee);color: #fff;}
.login .signup{text-align: right;margin-top: 20px;cursor: pointer;}
.login .logo{width: 100%;height: 100%;}
.login .logo img{width: 100%;height: 100%;}
</style>