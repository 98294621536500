import {request} from './request'
import qs from 'qs'
import Cookies from 'js-cookie'

const maker_id=Cookies.get("maker_id")
//登陆
export function login(params) {
    return request({
    url:'/profile/login.php',
    data:qs.stringify(params),
    method:'post'})
}

//注册
/*
email=a
password=b
brand_name=c
logo=http://pic.com/12345.jpg   
*/
export function register(params) {
    let data=qs.stringify(params)
    return  request({url:'/profile/register.php',data,method:'post'})
}

export function editProfile(params){
    params.maker_id = Cookies.get("maker_id");
    return  request({url:`/profile/editProfile.php`,data:qs.stringify(params),method:'post'})
}

export function getProfile() {
    var maker_id = Cookies.get("maker_id");
    return request({url:'/profile/getProfile.php',params:{maker_id}})
}





