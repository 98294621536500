import axios from 'axios'
export function request(config){
    const instance=axios.create({
        // baseURL:'https://shady.ecomgear.cn/kenneth_testing/maker',
        baseURL:'https://labs.datahunt.app',
        // baseURL:'http://makeradmin.net',
        timeout:30000,
        headers:{'Content-Type':'application/x-www-form-urlencoded'},
    })
    //请求拦截
    instance.interceptors.request.use(res=>{
        return res
    },err=>{
        return err
    })
    //响应拦截
    instance.interceptors.response.use(res=>{
        return res.data
    },err=>{
        return err
    })
    
    return instance(config)
}



/**
 * 
 * //Profile
[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getProfile.php?maker_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/editProfile.php
maker_id=1
company_name=a
contact_name=b
contact_no=c
company_address=d
website=e
brand_name=f
logo=http://pic.com/12345.jpg

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/register.php
email=a
password=b
brand_name=c
logo=http://pic.com/12345.jpg   
(error_code=406=>password muchst be at least 4 characters, error_code=407=>email address has already beend used)

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/login.php
email=a
password=b   
(error_code=404=>invalid login)


//Press
[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getPresses.php?page=1&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getPress.php?maker_id=1&press_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/deletePress.php
maker_id=1
press_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/addPress.php
maker_id=1
title=a
author=b
content=c
keywords=xxx,yyy,zzz
img=http://pic.com/12345.jpg
post_date=2021-04-23

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/editPress.php
maker_id=1
press_id=1
title=a
author=b
content=c
keywords=xxx,yyy,zzz
img=http://pic.com/12345.jpg
post_date=2021-04-23


//Media
[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getMediaTypes.php?&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getMedias.php?page=1maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getMedia.php?maker_id=1&press_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/deleteMedia.php
maker_id=1
press_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/addMedia.php
maker_id=1
asset=a
url=b
description=c
type=d
img=http://pic.com/12345.jpg

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/press/editMedia.php
maker_id=1
media_id=1
asset=a
url=b
description=c
type=d
img=http://pic.com/12345.jpg

//image upload
[POST] http://shady.ecomgear.cn/kenneth_testing/maker/img/uploadPic.php
maker_id=1  
file=xxxx

(for logo in registration, just input "register" in maker_id)



//Product
[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getProductColors.php?page=1&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getProductCategories.php?page=1&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getProducts.php?page=1&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/press/getProduct.php?maker_id=1&product_id=1

 * 
 * 
 * 
 */