<template>
       <div>
           <Login-signup @setupBtn="loginBtn" >
               <div slot="content">
                    <input v-model="form.email" class='input-item' type='text' :placeholder="$t('login.email')" />
                    <input v-model="form.password" class='input-item' type='password' :placeholder="$t('login.password')" />
               </div>
                <div slot='tolinkclick' @click="toRegisterBtn" class="signup">{{$t('login.item_1')}}<span>{{$t('login.item_2')}}</span></div>
                <div slot='tolinkclick2' @click="toResetBtn" class="signup"><span>{{$t('login.item_3')}}</span></div>
           </Login-signup>
       </div>
</template>

<script>
import LoginSignup from 'components/content/LoginSignup'
import {login} from '@/network/common'
import Cookies from 'js-cookie'
export default {
    name: 'NodeprojectLogin',
    components: { LoginSignup },
    directives: {  },
    data() {
        return {
           form:{email:'',
            password:''}
        };
    },
    mounted() {
    },
    methods: {
        loginBtn(){
            //登陆按钮
        let data=this.form
        login(data)
           .then(res=>{
               if(res.header.code==200){
                  Cookies.set("maker_id",res.body.maker_id)
                  this.$router.push('/home')
               }
               else{
                this.$message.info(res.header.msg);
               }
           })
           .catch(e=>{
               console.log(e)
           });
        },
          toRegisterBtn(){
            this.$router.replace('/register');
        },
          toResetBtn(){
            this.$router.replace('/resetpassword');
        }
    },
};
</script>

<style  scoped>

</style>